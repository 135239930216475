import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY, GET_EBAY_ITEM_BRANDS_QUERY, GET_EBAY_ITEM_TAGS_QUERY } from "@/graphql/queries/ebay/general/ebayGeneralQueries";
import { UPDATE_EBAY_ITEM_MUTATION } from "@/graphql/mutations/ebayMutations";
import { useQuery, useMutation } from "@apollo/client";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    product: any;
}

const decodeBase64Id = (encodedId: string): string => {
    const decodedString = atob(encodedId);
    return decodedString.split(":")[1]; // Assuming the format is "Type:ID"
};

const EditProductModal: React.FC<ModalProps> = ({ isOpen, closeModal, product }) => {
    const navigate = useNavigate();

    const { data: sourceLocationsData } = useQuery(GET_EBAY_ITEM_SOURCE_LOCATIONS_QUERY);
    const { data: brandsData } = useQuery(GET_EBAY_ITEM_BRANDS_QUERY);
    const { data: tagsData } = useQuery(GET_EBAY_ITEM_TAGS_QUERY);

    const [updateEbayItem] = useMutation(UPDATE_EBAY_ITEM_MUTATION);

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);

        // Convert the id from base64 to string
        const itemId = decodeBase64Id(product.id);

        const variables: any = { itemId: itemId };

        const costOfGoodsPerItem = formData.get('costOfGoodsPerItem');
        if (costOfGoodsPerItem) {
            variables.costOfGoodsPerItem = parseFloat(costOfGoodsPerItem as string).toFixed(2);
        }

        const packingCostsPerItem = formData.get('packingCostsPerItem');
        if (packingCostsPerItem) {
            variables.packingCostsPerItem = parseFloat(packingCostsPerItem as string).toFixed(2);
        }

        const sourcedAt = formData.get('sourcedAt');
        if (sourcedAt) {
            variables.sourcedAt = new Date(sourcedAt as string).toISOString();
        }

        const sourceLocationId = formData.get('sourceLocation');
        if (sourceLocationId) {
            variables.sourceLocationId = sourceLocationId as string;
        }

        const storageLocation = formData.get('storageLocation');
        if (storageLocation) {
            variables.storageLocation = storageLocation as string;
        }

        const itemBrandId = formData.get('brand');
        if (itemBrandId) {
            variables.itemBrandId = itemBrandId as string;
        }

        const tagIds = formData.getAll('tags');
        if (tagIds.length > 0) {
            variables.tagIds = tagIds as string[];
        }

        try {
            await updateEbayItem({ variables });
            closeModal();
            navigate(0);
        } catch (error) {
            console.error('Error updating eBay item:', error);
        }
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="border-2 fixed inset-0 bg-white dark:bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="border-2 border-gray-900 dark:border-0 relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 lg:pt-4">
                                <div className="mb-3">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 dark:text-white"
                                    >
                                        Edit Product Details
                                    </Dialog.Title>
                                </div>
                                <div className="mb-3">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="costOfGoodsPerItem"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Cost of Goods Per Item
                                            </label>
                                            <input
                                                type="number"
                                                step="0.01"
                                                name="costOfGoodsPerItem"
                                                id="costOfGoodsPerItem"
                                                className="mt-1 block w-full px-3 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                defaultValue={product.costOfGoodsPerItem}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="packingCostsPerItem"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Packing Costs Per Item
                                            </label>
                                            <input
                                                type="number"
                                                step="0.01"
                                                name="packingCostsPerItem"
                                                id="packingCostsPerItem"
                                                className="mt-1 block w-full px-3 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                defaultValue={product.packingCostsPerItem}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="sourcedAt"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Purchased Date
                                            </label>
                                            <input
                                                type="date"
                                                name="sourcedAt"
                                                id="sourcedAt"
                                                className="mt-1 block w-full px-3 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                defaultValue={product.sourcedAt ? new Date(product.sourcedAt).toISOString().split('T')[0] : ''}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="sourceLocation"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Source Location
                                            </label>
                                            <select
                                                name="sourceLocation"
                                                id="sourceLocation"
                                                className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                defaultValue={product.sourceLocation?.id || ''}
                                            >
                                                {sourceLocationsData?.sourceLocations.map((sourceLocation: any) => (
                                                    <option key={sourceLocation.id} value={sourceLocation.id}>
                                                        {sourceLocation.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="storageLocation"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Storage Location
                                            </label>
                                            <input
                                                type="text"
                                                name="storageLocation"
                                                id="storageLocation"
                                                className="mt-1 block w-full px-3 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                defaultValue={product.storageLocation || ''}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="brand"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Brand
                                            </label>
                                            <select
                                                name="brand"
                                                id="brand"
                                                className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                defaultValue={product.itemBrand?.id || ''}
                                            >
                                                {brandsData?.brands.map((brand: any) => (
                                                    <option key={brand.id} value={brand.id}>
                                                        {brand.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="tags"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Tags
                                            </label>
                                            <select
                                                name="tags"
                                                id="tags"
                                                className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                defaultValue={product.tags.map((tag: any) => tag.id) || []}
                                                multiple
                                            >
                                                {tagsData?.ebayItemTags.map((tag: any) => (
                                                    <option key={tag.id} value={tag.id}>
                                                        {tag.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 dark:text-white shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="border-2 border-gray-900 dark:border-0 flex w-full justify-center rounded-md dark:bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 dark:text-white shadow-sm dark:hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EditProductModal;
