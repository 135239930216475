import React from "react";
import { Link } from "react-router-dom";
import { NavigationChild } from "@/types/globalTypes";
import { classNames } from "@/helpers/common/classNames";

interface NavItemProps {
    item: NavigationChild;
    isActive: boolean;
}

export const SidebarNavItemChild: React.FC<NavItemProps> = ({
    item,
    isActive,
}) => {
    return (
        <li>
            <Link
                to={item.href}
                className={classNames(
                    isActive
                        ? "bg-brand dark:text-white"
                        : "hover:bg-brand dark:text-gray-200 dark:hover:text-white",
                    "group flex items-center justify-between gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                )}
            >
                <span className="flex items-center gap-x-3">{item.name}</span>
            </Link>
        </li>
    );
};
