import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { CREATE_SOURCE_LOCATION_MUTATION } from "@/graphql/mutations/ebayMutations";
import { useMutation } from "@apollo/client";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

const CreateSourceLocationModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
    const navigate = useNavigate();

    const [createSourceLocation] = useMutation(CREATE_SOURCE_LOCATION_MUTATION);

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);

        const variables: any = {};

        const name = formData.get('name') as string;
        if (name) variables.name = name;

        const address = formData.get('address') as string;
        if (address) variables.address = address;

        const city = formData.get('city') as string;
        if (city) variables.city = city;

        const state = formData.get('state') as string;
        if (state) variables.state = state;

        const zipCode = formData.get('zipCode') as string;
        if (zipCode) variables.zipCode = zipCode;

        const country = formData.get('country') as string;
        if (country) variables.country = country;

        try {
            await createSourceLocation({ variables });
            closeModal();
            navigate(0);
        } catch (error) {
            console.error('Error creating source location:', error);
        }
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="border-2 fixed inset-0 bg-white dark:bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="border-2 border-gray-900 dark:border-0 relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 lg:pt-4">
                                <div className="mb-3">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 dark:text-white"
                                    >
                                        Add Source Location
                                    </Dialog.Title>
                                </div>
                                <div className="mb-3">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="name"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className="mt-1 block w-full px-3 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                maxLength={30}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="address"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Address
                                            </label>
                                            <input
                                                type="text"
                                                name="address"
                                                id="address"
                                                className="mt-1 block w-full px-3 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                maxLength={100}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="city"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                City
                                            </label>
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                className="mt-1 block w-full px-3 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                maxLength={50}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="state"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                State
                                            </label>
                                            <input
                                                type="text"
                                                name="state"
                                                id="state"
                                                className="mt-1 block w-full px-3 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                maxLength={50}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="zipCode"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Zip Code
                                            </label>
                                            <input
                                                type="text"
                                                name="zipCode"
                                                id="zipCode"
                                                className="mt-1 block w-full px-3 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                maxLength={10}
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <label
                                                htmlFor="country"
                                                className="block text-sm font-medium dark:text-white"
                                            >
                                                Country
                                            </label>
                                            <input
                                                type="text"
                                                name="country"
                                                id="country"
                                                className="mt-1 block w-full px-3 py-2 border border-gray-900 rounded-md shadow-sm focus:ring-brand focus:border-brand sm:text-sm"
                                                maxLength={50}
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 dark:text-white shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="border-2 border-gray-900 dark:border-0 flex w-full justify-center rounded-md dark:bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 dark:text-white shadow-sm dark:hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default CreateSourceLocationModal;
