import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_EBAY_ITEM_QUERY } from "@/graphql/queries/ebay/products/ebayProductsQueries";

import ProductDetailsComponent from "@/components/dashboard/products/ProductDetailsComponent";
import ProductTimelineComponent from "@/components/dashboard/products/ProductTimelineComonent";
import ProductTotalsComponent from "@/components/dashboard/products/ProductTotalsComponent";
import EditProductModal from "@/components/dashboard/products/modals/EditProductModal";
import ScreenshotProductModal from "@/components/dashboard/products/modals/ScreenshotProductModal";

const EbayProductPage: React.FC = () => {
    const { product_id } = useParams<{ product_id: string }>();

    const { data, loading, error } = useQuery(GET_EBAY_ITEM_QUERY, {
        variables: { ebayItemId: product_id },
    });

    const [isEditProductModalOpen, setIsEditProductModalOpen] =
        useState(false);

    const [isScreenshotModalOpen, setIsScreenshotModalOpen] = useState(false);

    const toggleEditProductModal = () => {
        setIsEditProductModalOpen(!isEditProductModalOpen);
    };

    const toggleScreenshotModal = () => {
        setIsScreenshotModalOpen(!isScreenshotModalOpen);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const product = data?.ebayItem;

    console.log(product);

    return (
        <div className="dark:bg-gray-900">
            <div className="py-2 dark:bg-gray-900">
                <div className="flex justify-between items-center pb-2 px-2 sm:px-2 lg:px-1">
                    <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                        {product?.itemName}
                    </h3>
                    <div className="flex items-center">
                        {product.tags.map((tag: any) => (
                            <span key={tag.id} className="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white px-2 py-1 rounded-full mr-2">
                                {tag.name}
                            </span>
                        ))}
                        <button
                            onClick={toggleEditProductModal}
                            className="inline-flex justify-center py-2 px-4 text-sm bg-white text-black border-black border dark:border-white dark:text-white dark:bg-gray-700 rounded-lg mr-2"
                        >
                            Edit Product
                        </button>
                        <button
                            onClick={toggleScreenshotModal}
                            className="inline-flex justify-center py-2 px-4 text-sm bg-white text-black border-black border dark:border-white dark:text-white dark:bg-gray-700 rounded-lg"
                        >
                            Share
                        </button>
                    </div>

                </div>
            </div>
            <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-7">
                <ProductDetailsComponent product={product} />
                <div className="rounded-lg shadow sm:col-span-5">
                    <ProductTotalsComponent product={product} />
                    <ProductTimelineComponent product={product} />
                </div>
            </dl>
            <EditProductModal
                product={product}
                isOpen={isEditProductModalOpen}
                closeModal={toggleEditProductModal}
            />
            <ScreenshotProductModal
                product={product}
                isOpen={isScreenshotModalOpen}
                closeModal={toggleScreenshotModal}
            />
        </div>
    );
};

export default EbayProductPage;
