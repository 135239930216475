import { gql } from "@apollo/client";

export const GET_EBAY_SALES_QUERY = gql`
    query GetEbaySales(
        $after: String
        $before: String
        $ebayItem_ItemBrand_Id: UUID
        $ebayItem_ItemCategory_Id: UUID
        $ebayItem_ListedPrice_Gte: Decimal
        $ebayItem_ListedPrice_Lte: Decimal
        $ebayItem_SourceLocation_Id: ID
        $ebayItem_Tags_Id: ID
        $first: Int
        $last: Int
        $offset: Int
        $soldPrice_Gte: Decimal
        $soldPrice_Lte: Decimal
    ) {
        ebaySales(
            after: $after
            before: $before
            ebayItem_ItemBrand_Id: $ebayItem_ItemBrand_Id
            ebayItem_ItemCategory_Id: $ebayItem_ItemCategory_Id
            ebayItem_ListedPrice_Gte: $ebayItem_ListedPrice_Gte
            ebayItem_ListedPrice_Lte: $ebayItem_ListedPrice_Lte
            ebayItem_SourceLocation_Id: $ebayItem_SourceLocation_Id
            ebayItem_Tags_Id: $ebayItem_Tags_Id
            last: $last
            first: $first
            offset: $offset
            soldPrice_Gte: $soldPrice_Gte
            soldPrice_Lte: $soldPrice_Lte
            orderBy: "-sold_at"
        ) {
            edges {
                cursor
                node {
                    createdAt
                    ebayFees
                    ebayOrderId
                    ebayPromotionFees
                    id
                    quantitySold
                    returned
                    shippedAt
                    shippingCost
                    shippingPrice
                    soldAt
                    soldPrice
                    profitAndRoi {
                        id
                        totalProfitLoss
                        totalReturnOnInvestment
                    }
                    updatedAt
                    ebayItem {
                        costOfGoodsPerItem
                        createdAt
                        ebayItemId
                        id
                        imageUrl
                        itemCondition
                        itemName
                        listedAt
                        listedPrice
                        listingType
                        packingCostsPerItem
                        quantity
                        sourcedAt
                        storageLocation
                        profitAndRoi {
                            id
                            totalProfitLoss
                            totalReturnOnInvestment
                            totalSales
                            totalRevenue
                            totalCosts
                            totalEbayFees
                        }
                        unsold
                        updatedAt
                        tags {
                            createdAt
                            id
                            name
                            updatedAt
                        }
                        sourceLocation {
                            address
                            city
                            country
                            createdAt
                            id
                            name
                            state
                            updatedAt
                            zipCode
                        }
                        itemCategory {
                            categoryId
                            categoryName
                            createdAt
                            id
                            updatedAt
                        }
                        itemBrand {
                            createdAt
                            id
                            name
                            updatedAt
                        }
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`;

export const GET_READY_TO_SHIP_EBAY_SALES_QUERY = gql`
    query GetReadyToShipEbaySales(
        $after: String
        $before: String
        $ebayItem_ItemBrand_Id: UUID
        $ebayItem_ItemCategory_Id: UUID
        $ebayItem_ListedPrice_Gte: Decimal
        $ebayItem_ListedPrice_Lte: Decimal
        $ebayItem_SourceLocation_Id: ID
        $ebayItem_Tags_Id: ID
        $first: Int
        $last: Int
        $offset: Int
        $soldPrice_Gte: Decimal
        $soldPrice_Lte: Decimal
    ) {
        readyToShipEbaySales(
            after: $after
            before: $before
            ebayItem_ItemBrand_Id: $ebayItem_ItemBrand_Id
            ebayItem_ItemCategory_Id: $ebayItem_ItemCategory_Id
            ebayItem_ListedPrice_Gte: $ebayItem_ListedPrice_Gte
            ebayItem_ListedPrice_Lte: $ebayItem_ListedPrice_Lte
            ebayItem_SourceLocation_Id: $ebayItem_SourceLocation_Id
            ebayItem_Tags_Id: $ebayItem_Tags_Id
            last: $last
            first: $first
            offset: $offset
            soldPrice_Gte: $soldPrice_Gte
            soldPrice_Lte: $soldPrice_Lte
            orderBy: "-sold_at"
        ) {
            edges {
                cursor
                node {
                    createdAt
                    ebayFees
                    ebayOrderId
                    ebayPromotionFees
                    id
                    quantitySold
                    returned
                    shippedAt
                    shippingCost
                    shippingPrice
                    soldAt
                    soldPrice
                    profitAndRoi {
                        id
                        totalProfitLoss
                        totalReturnOnInvestment
                    }
                    updatedAt
                    ebayItem {
                        costOfGoodsPerItem
                        createdAt
                        ebayItemId
                        id
                        imageUrl
                        itemCondition
                        itemName
                        listedAt
                        listedPrice
                        listingType
                        packingCostsPerItem
                        quantity
                        sourcedAt
                        storageLocation
                        profitAndRoi {
                            id
                            totalProfitLoss
                            totalReturnOnInvestment
                            totalSales
                            totalRevenue
                            totalCosts
                            totalEbayFees
                        }
                        unsold
                        updatedAt
                        tags {
                            createdAt
                            id
                            name
                            updatedAt
                        }
                        sourceLocation {
                            address
                            city
                            country
                            createdAt
                            id
                            name
                            state
                            updatedAt
                            zipCode
                        }
                        itemCategory {
                            categoryId
                            categoryName
                            createdAt
                            id
                            updatedAt
                        }
                        itemBrand {
                            createdAt
                            id
                            name
                            updatedAt
                        }
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`;

export const GET_SHIPPED_EBAY_SALES_QUERY = gql`
    query GetShippedEbaySales(
        $after: String
        $before: String
        $ebayItem_ItemBrand_Id: UUID
        $ebayItem_ItemCategory_Id: UUID
        $ebayItem_ListedPrice_Gte: Decimal
        $ebayItem_ListedPrice_Lte: Decimal
        $ebayItem_SourceLocation_Id: ID
        $ebayItem_Tags_Id: ID
        $first: Int
        $last: Int
        $offset: Int
        $soldPrice_Gte: Decimal
        $soldPrice_Lte: Decimal
    ) {
        shippedEbaySales(
            after: $after
            before: $before
            ebayItem_ItemBrand_Id: $ebayItem_ItemBrand_Id
            ebayItem_ItemCategory_Id: $ebayItem_ItemCategory_Id
            ebayItem_ListedPrice_Gte: $ebayItem_ListedPrice_Gte
            ebayItem_ListedPrice_Lte: $ebayItem_ListedPrice_Lte
            ebayItem_SourceLocation_Id: $ebayItem_SourceLocation_Id
            ebayItem_Tags_Id: $ebayItem_Tags_Id
            last: $last
            first: $first
            offset: $offset
            soldPrice_Gte: $soldPrice_Gte
            soldPrice_Lte: $soldPrice_Lte
            orderBy: "-sold_at"
        ) {
            edges {
                cursor
                node {
                    createdAt
                    ebayFees
                    ebayOrderId
                    ebayPromotionFees
                    id
                    quantitySold
                    returned
                    shippedAt
                    shippingCost
                    shippingPrice
                    soldAt
                    soldPrice
                    profitAndRoi {
                        id
                        totalProfitLoss
                        totalReturnOnInvestment
                    }
                    updatedAt
                    ebayItem {
                        costOfGoodsPerItem
                        createdAt
                        ebayItemId
                        id
                        imageUrl
                        itemCondition
                        itemName
                        listedAt
                        listedPrice
                        listingType
                        packingCostsPerItem
                        quantity
                        sourcedAt
                        storageLocation
                        profitAndRoi {
                            id
                            totalProfitLoss
                            totalReturnOnInvestment
                            totalSales
                            totalRevenue
                            totalCosts
                            totalEbayFees
                        }
                        unsold
                        updatedAt
                        tags {
                            createdAt
                            id
                            name
                            updatedAt
                        }
                        sourceLocation {
                            address
                            city
                            country
                            createdAt
                            id
                            name
                            state
                            updatedAt
                            zipCode
                        }
                        itemCategory {
                            categoryId
                            categoryName
                            createdAt
                            id
                            updatedAt
                        }
                        itemBrand {
                            createdAt
                            id
                            name
                            updatedAt
                        }
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`;

export const GET_MISSING_DATA_EBAY_SALES_QUERY = gql`
    query GetMissingDataEbaySales(
        $after: String
        $before: String
        $ebayItem_ItemBrand_Id: UUID
        $ebayItem_ItemCategory_Id: UUID
        $ebayItem_ListedPrice_Gte: Decimal
        $ebayItem_ListedPrice_Lte: Decimal
        $ebayItem_SourceLocation_Id: ID
        $ebayItem_Tags_Id: ID
        $first: Int
        $last: Int
        $offset: Int
        $soldPrice_Gte: Decimal
        $soldPrice_Lte: Decimal
    ) {
        missingDataEbaySales(
            after: $after
            before: $before
            ebayItem_ItemBrand_Id: $ebayItem_ItemBrand_Id
            ebayItem_ItemCategory_Id: $ebayItem_ItemCategory_Id
            ebayItem_ListedPrice_Gte: $ebayItem_ListedPrice_Gte
            ebayItem_ListedPrice_Lte: $ebayItem_ListedPrice_Lte
            ebayItem_SourceLocation_Id: $ebayItem_SourceLocation_Id
            ebayItem_Tags_Id: $ebayItem_Tags_Id
            last: $last
            first: $first
            offset: $offset
            soldPrice_Gte: $soldPrice_Gte
            soldPrice_Lte: $soldPrice_Lte
            orderBy: "-sold_at"
        ) {
            edges {
                cursor
                node {
                    createdAt
                    ebayFees
                    ebayOrderId
                    ebayPromotionFees
                    id
                    quantitySold
                    returned
                    shippedAt
                    shippingCost
                    shippingPrice
                    soldAt
                    soldPrice
                    profitAndRoi {
                        id
                        totalProfitLoss
                        totalReturnOnInvestment
                    }
                    updatedAt
                    ebayItem {
                        costOfGoodsPerItem
                        createdAt
                        ebayItemId
                        id
                        imageUrl
                        itemCondition
                        itemName
                        listedAt
                        listedPrice
                        listingType
                        packingCostsPerItem
                        quantity
                        sourcedAt
                        storageLocation
                        profitAndRoi {
                            id
                            totalProfitLoss
                            totalReturnOnInvestment
                            totalSales
                            totalRevenue
                            totalCosts
                            totalEbayFees
                        }
                        unsold
                        updatedAt
                        tags {
                            createdAt
                            id
                            name
                            updatedAt
                        }
                        sourceLocation {
                            address
                            city
                            country
                            createdAt
                            id
                            name
                            state
                            updatedAt
                            zipCode
                        }
                        itemCategory {
                            categoryId
                            categoryName
                            createdAt
                            id
                            updatedAt
                        }
                        itemBrand {
                            createdAt
                            id
                            name
                            updatedAt
                        }
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`;

export const GET_RETURNED_EBAY_SALES_QUERY = gql`
    query GetReturnedEbaySales(
        $after: String
        $before: String
        $ebayItem_ItemBrand_Id: UUID
        $ebayItem_ItemCategory_Id: UUID
        $ebayItem_ListedPrice_Gte: Decimal
        $ebayItem_ListedPrice_Lte: Decimal
        $ebayItem_SourceLocation_Id: ID
        $ebayItem_Tags_Id: ID
        $first: Int
        $last: Int
        $offset: Int
        $soldPrice_Gte: Decimal
        $soldPrice_Lte: Decimal
    ) {
        returnedEbaySales(
            after: $after
            before: $before
            ebayItem_ItemBrand_Id: $ebayItem_ItemBrand_Id
            ebayItem_ItemCategory_Id: $ebayItem_ItemCategory_Id
            ebayItem_ListedPrice_Gte: $ebayItem_ListedPrice_Gte
            ebayItem_ListedPrice_Lte: $ebayItem_ListedPrice_Lte
            ebayItem_SourceLocation_Id: $ebayItem_SourceLocation_Id
            ebayItem_Tags_Id: $ebayItem_Tags_Id
            last: $last
            first: $first
            offset: $offset
            soldPrice_Gte: $soldPrice_Gte
            soldPrice_Lte: $soldPrice_Lte
            orderBy: "-sold_at"
        ) {
            edges {
                cursor
                node {
                    createdAt
                    ebayFees
                    ebayOrderId
                    ebayPromotionFees
                    id
                    quantitySold
                    returned
                    shippedAt
                    shippingCost
                    shippingPrice
                    soldAt
                    soldPrice
                    profitAndRoi {
                        id
                        totalProfitLoss
                        totalReturnOnInvestment
                    }
                    updatedAt
                    ebayItem {
                        costOfGoodsPerItem
                        createdAt
                        ebayItemId
                        id
                        imageUrl
                        itemCondition
                        itemName
                        listedAt
                        listedPrice
                        listingType
                        packingCostsPerItem
                        quantity
                        sourcedAt
                        storageLocation
                        profitAndRoi {
                            id
                            totalProfitLoss
                            totalReturnOnInvestment
                            totalSales
                            totalRevenue
                            totalCosts
                            totalEbayFees
                        }
                        unsold
                        updatedAt
                        tags {
                            createdAt
                            id
                            name
                            updatedAt
                        }
                        sourceLocation {
                            address
                            city
                            country
                            createdAt
                            id
                            name
                            state
                            updatedAt
                            zipCode
                        }
                        itemCategory {
                            categoryId
                            categoryName
                            createdAt
                            id
                            updatedAt
                        }
                        itemBrand {
                            createdAt
                            id
                            name
                            updatedAt
                        }
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`;

export const GET_EBAY_SALES_PROFITS_AND_ROI_QUERY = gql`
    query MyQuery($ebaySaleSoldAtGte: DateTime, $ebaySaleSoldAtLte: DateTime) {
        saleProfitAndRois(
            ebaySaleSoldAtGte: $ebaySaleSoldAtGte
            ebaySaleSoldAtLte: $ebaySaleSoldAtLte
        ) {
            id
            totalProfitLoss
            totalReturnOnInvestment
            createdAt
            updatedAt
            ebaySale {
                soldAt
            }
        }
    }
`;
