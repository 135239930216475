import ReactDOM from "react-dom/client";
import { router } from "./router/browserRouter";
import { RouterProvider } from "react-router-dom";
import ApolloProvider from "./providers/ApolloProvider";
import { AuthProvider } from "./providers/AuthProvider";
import ThemeProvider from "./providers/ThemeProvider";
import StripeProvider from "./providers/StripeProvider";
import Sentry from "./providers/SentryProvider";
import "./index.css";
import '@stripe/stripe-js';

ReactDOM.createRoot(document.getElementById("root")!).render(
    <Sentry.ErrorBoundary fallback={<p>Something went wrong...</p>}>
        <ThemeProvider>
            <ApolloProvider>
                <AuthProvider>
                    <StripeProvider>
                        <RouterProvider router={router} />
                    </StripeProvider>
                </AuthProvider>
            </ApolloProvider>
        </ThemeProvider>
    </Sentry.ErrorBoundary>
);
