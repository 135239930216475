import { NavigationItem } from "@/types/globalTypes";

export const navigationData: NavigationItem[] = [
    {
        name: "Dashboard",
        href: "",
        icon: "home-outline",
        children: [],
    },
    {
        name: "Inventory",
        href: "",
        icon: "fi-package",
        children: [
            {
                name: "Active Products",
                href: "/dashboard/inventory/active-products",
            },
            {
                name: "Unlisted Products",
                href: "/dashboard/inventory/unlisted-products",
            },
            {
                name: "Missing Data",
                href: "/dashboard/inventory/missing-data",
            },
        ],
    },
    {
        name: "Sold",
        href: "",
        icon: "currency-pound-outline",
        children: [
            {
                name: "All Sold",
                href: "/dashboard/sold/all-sold",
            },
            {
                name: "Ready to Ship",
                href: "/dashboard/sold/ready-to-ship",
            },
            {
                name: "Shipped",
                href: "/dashboard/sold/shipped",
            },
            {
                name: "Missing Data",
                href: "/dashboard/sold/missing-data",
            },
        ],
    },
    {
        name: "Insights",
        href: "/dashboard/insights",
        icon: "chart-bar-outline",
        children: [],
    },
    {
        name: "Manage",
        href: "",
        icon: "cog-6-tooth-outline",
        children: [
            {
                name: "Tags",
                href: "/dashboard/manage/tags",
            },
            {
                name: "Source Locations",
                href: "/dashboard/manage/source-locations",
            },
            {
                name: "Storage Locations",
                href: "/dashboard/manage/storage-locations",
            },
        ],
    },
    {
        name: "Settings",
        href: "",
        icon: "cog-6-tooth-outline",
        children: [
            {
                name: "Account",
                href: "/dashboard/settings/account",
            },
            {
                name: "Notifications",
                href: "/dashboard/settings/notifications",
            },
            {
                name: "Billing",
                href: "/dashboard/settings/billing",
            },
        ],
    },
];
