import React, { useState } from "react";
import useSourceLocations from "@/hooks/source-locations/useSourceLocations";
import CreateSourceLocationModal from "./tags/CreateSourceLocationModal";

interface Column {
    key: string;
    label: string;
    render?: (value: any, item?: any) => React.ReactNode;
}

interface TableComponentProps {
    columns: Column[];
}

const SourceLocationsTableComponent: React.FC<TableComponentProps> = ({ columns }) => {
    const {
        data,
        loading,
        error,
        handleNextPage,
        handlePreviousPage,
        pageInfo,
    } = useSourceLocations();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const products = data?.paginatedSourceLocations?.edges || [];

    return (
        <div className="dark:bg-gray-900">
            <div className="py-2 dark:bg-gray-900">
                <div className="flex justify-between items-center pb-2 px-2 sm:px-2 lg:px-1">
                    <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
                        Source Locations
                    </h3>
                    <div className="flex space-x-2">
                        <button
                            className="px-4 py-2 border-2 border-gray-900 dark:border-0 bg-white text-gray-900 dark:bg-gray-300 rounded-lg"
                            onClick={() => openModal()}
                        >
                            Add Source Location
                        </button>
                    </div>
                </div>

                <div className="px-2 sm:px-2 lg:px-1">
                    <div className="flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y dark:divide-gray-700">
                                    <thead>
                                        <tr>
                                            {columns.map((col) => (
                                                <th
                                                    key={col.key}
                                                    scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-white sm:pl-0"
                                                >
                                                    {col.label}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y dark:divide-gray-800">
                                        {products.map(({ node }: any) => (
                                            <tr key={node.id}>
                                                {columns.map((col) => (
                                                    <td
                                                        key={col.key}
                                                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm dark:text-white sm:pl-0"
                                                    >
                                                        {col.render ? col.render(node[col.key], node) : (node[col.key] != null ? node[col.key] : '')}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="mt-4">
                                    <button
                                        onClick={handlePreviousPage}
                                        disabled={!pageInfo.hasPreviousPage}
                                        className="mr-2 px-4 py-2 border-2 border-gray-900 dark:border-0 bg-white text-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded"
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={!pageInfo.hasNextPage}
                                        className="px-4 py-2 border-2 border-gray-900 dark:border-0 bg-white text-gray-900 dark:bg-gray-300 dark:text-gray-900 rounded"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CreateSourceLocationModal isOpen={isModalOpen} closeModal={closeModal} />
            </div>
        </div>
    );
};

export default SourceLocationsTableComponent;
