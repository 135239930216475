import { gql } from "@apollo/client";

export const GET_INVENTORY_MISSING_DATA_EBAY_ITEMS_QUERY = gql`
    query GetInventoryMissingDataEbayItems(
        $after: String
        $before: String
        $tags: ID
        $sourceLocationId: ID
        $listedPriceLte: Decimal
        $listedPriceGte: Decimal
        $itemCategoryId: UUID
        $itemBrandId: UUID
    ) {
        inventoryMissingDataEbayItems(
            after: $after
            before: $before
            tags_Id: $tags
            sourceLocation_Id: $sourceLocationId
            listedPrice_Lte: $listedPriceLte
            listedPrice_Gte: $listedPriceGte
            itemCategory_Id: $itemCategoryId
            itemBrand_Id: $itemBrandId
            orderBy: "-listed_at"
        ) {
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
            edges {
                cursor
                node {
                    id
                    imageUrl
                    itemCondition
                    itemName
                    listedAt
                    listedPrice
                    listingType
                    packingCostsPerItem
                    quantity
                    sourcedAt
                    storageLocation
                    unsold
                    updatedAt
                    ebayItemId
                    createdAt
                    costOfGoodsPerItem
                    profitAndRoi {
                        id
                        totalProfitLoss
                        totalReturnOnInvestment
                        totalSales
                        totalRevenue
                        totalCosts
                        totalEbayFees
                    }
                    ebayItemEvents {
                        createdAt
                        eventType
                        id
                        newValue
                        previousValue
                        revisedFieldName
                        updatedAt
                    }
                    tags {
                        createdAt
                        id
                        name
                        updatedAt
                    }
                    itemBrand {
                        id
                        name
                        createdAt
                        updatedAt
                    }
                    itemCategory {
                        id
                        categoryId
                        categoryName
                        createdAt
                        updatedAt
                    }
                    sourceLocation {
                        id
                        name
                        address
                        city
                        state
                        zipCode
                        country
                        createdAt
                        updatedAt
                    }
                }
            }
        }
    }
`;
export const GET_UNSOLD_EBAY_ITEMS_QUERY = gql`
    query GetUnsoldEbayItems(
        $after: String
        $before: String
        $tags: ID
        $sourceLocationId: ID
        $listedPriceLte: Decimal
        $listedPriceGte: Decimal
        $itemCategoryId: UUID
        $itemBrandId: UUID
    ) {
        unsoldEbayItems(
            after: $after
            before: $before
            tags_Id: $tags
            sourceLocation_Id: $sourceLocationId
            listedPrice_Lte: $listedPriceLte
            listedPrice_Gte: $listedPriceGte
            itemCategory_Id: $itemCategoryId
            itemBrand_Id: $itemBrandId
            orderBy: "-listed_at"
        ) {
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
            edges {
                cursor
                node {
                    id
                    imageUrl
                    itemCondition
                    itemName
                    listedAt
                    listedPrice
                    listingType
                    packingCostsPerItem
                    quantity
                    sourcedAt
                    storageLocation
                    unsold
                    updatedAt
                    ebayItemId
                    createdAt
                    costOfGoodsPerItem
                    profitAndRoi {
                        id
                        totalProfitLoss
                        totalReturnOnInvestment
                        totalSales
                        totalRevenue
                        totalCosts
                        totalEbayFees
                    }
                    ebayItemEvents {
                        createdAt
                        eventType
                        id
                        newValue
                        previousValue
                        revisedFieldName
                        updatedAt
                    }
                    tags {
                        createdAt
                        id
                        name
                        updatedAt
                    }
                    itemBrand {
                        id
                        name
                        createdAt
                        updatedAt
                    }
                    itemCategory {
                        id
                        categoryId
                        categoryName
                        createdAt
                        updatedAt
                    }
                    sourceLocation {
                        id
                        name
                        address
                        city
                        state
                        zipCode
                        country
                        createdAt
                        updatedAt
                    }
                }
            }
        }
    }
`;
export const GET_ACTIVE_EBAY_ITEMS_QUERY = gql`
    query GetActiveEbayItems(
        $after: String
        $before: String
        $tags: ID
        $sourceLocationId: ID
        $listedPriceLte: Decimal
        $listedPriceGte: Decimal
        $itemCategoryId: UUID
        $itemBrandId: UUID
    ) {
        activeEbayItems(
            after: $after
            before: $before
            tags_Id: $tags
            sourceLocation_Id: $sourceLocationId
            listedPrice_Lte: $listedPriceLte
            listedPrice_Gte: $listedPriceGte
            itemCategory_Id: $itemCategoryId
            itemBrand_Id: $itemBrandId
            orderBy: "-listed_at"
        ) {
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
            edges {
                cursor
                node {
                    id
                    imageUrl
                    itemCondition
                    itemName
                    listedAt
                    listedPrice
                    listingType
                    packingCostsPerItem
                    quantity
                    sourcedAt
                    storageLocation
                    unsold
                    updatedAt
                    ebayItemId
                    createdAt
                    costOfGoodsPerItem
                    profitAndRoi {
                        id
                        totalProfitLoss
                        totalReturnOnInvestment
                        totalSales
                        totalRevenue
                        totalCosts
                        totalEbayFees
                    }
                    ebayItemEvents {
                        createdAt
                        eventType
                        id
                        newValue
                        previousValue
                        revisedFieldName
                        updatedAt
                    }
                    tags {
                        createdAt
                        id
                        name
                        updatedAt
                    }
                    itemBrand {
                        id
                        name
                        createdAt
                        updatedAt
                    }
                    itemCategory {
                        id
                        categoryId
                        categoryName
                        createdAt
                        updatedAt
                    }
                    sourceLocation {
                        id
                        name
                        address
                        city
                        state
                        zipCode
                        country
                        createdAt
                        updatedAt
                    }
                }
            }
        }
    }
`;
