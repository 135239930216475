import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconComponent from "@/components/icon/IconComponent";
import { NavigationItem, NavigationChild } from "@/types/globalTypes";
import { classNames } from "@/helpers/common/classNames";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

interface MobileNavItemProps {
    item: NavigationItem | NavigationChild;
    isActive: boolean;
}

export const MobileNavItem: React.FC<MobileNavItemProps> = ({
    item,
    isActive,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasChildren =
        "children" in item && item.children && item.children.length > 0;

    return (
        <React.Fragment>
            <li className="px-6">
                <Link
                    to={item.href}
                    onClick={(e) => {
                        if (hasChildren) {
                            e.preventDefault();
                            setIsOpen(!isOpen);
                        }
                    }}
                    className={classNames(
                        isActive
                            ? "bg-brand dark:text-white"
                            : "hover:bg-brand dark:text-gray-200 dark:hover:text-white",
                        "group flex w-full items-center justify-between rounded-md p-2 text-sm font-semibold leading-6",
                    )}
                >
                    <div className="flex items-center gap-x-3">
                        {"icon" in item && (
                            <IconComponent
                                iconName={item.icon}
                                className="h-6 w-6 shrink-0"
                                aria-hidden="true"
                            />
                        )}
                        {item.name}
                    </div>
                    {hasChildren && (
                        <span
                            className={`transition-transform duration-200 ${isOpen ? "rotate-180" : "rotate-0"}`}
                        >
                            <ChevronDownIcon className="h-5 w-5" />
                        </span>
                    )}
                </Link>
            </li>
            {isOpen && hasChildren && (
                <ul className="mt-1 space-y-1 pl-5">
                    {item.children.map((child, index) => (
                        <MobileNavItem
                            key={index}
                            item={child}
                            isActive={child.href === location.pathname}
                        />
                    ))}
                </ul>
            )}
        </React.Fragment>
    );
};
