import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { EbayAccount } from "@/types/globalTypes";
import { useAuth } from "@/hooks/auth/useAuth";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

const SwitchAccountModal: React.FC<ModalProps> = ({ isOpen, closeModal }) => {
    const navigate = useNavigate();
    const { user, setActiveEbayAccount } = useAuth();

    const availableEbayAccounts = user?.ebayAccounts || [];

    const handleSwitchAccountFormSubmit = (
        e: React.FormEvent<HTMLFormElement>,
    ) => {
        e.preventDefault();
        // Type assertion to inform TypeScript about the form structure
        const form = e.currentTarget;
        const ebayAccountInput = form.elements.namedItem(
            "ebayAccount",
        ) as HTMLInputElement;

        const ebayAccount = availableEbayAccounts.find(
            (account: EbayAccount) => account.id === ebayAccountInput.value,
        );

        if (ebayAccount) {
            setActiveEbayAccount(ebayAccount.id);
        }
        navigate(`/dashboard`);
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-900 px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 lg:pt-4">
                                <div className="mb-3">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-white"
                                    >
                                        Switch eBay Account
                                    </Dialog.Title>
                                </div>
                                <div className="mb-3">
                                    <form
                                        onSubmit={handleSwitchAccountFormSubmit}
                                    >
                                        <div className="mt-1">
                                            <select
                                                id="ebayAccount"
                                                name="ebayAccount"
                                                className="block w-full rounded-md border-0 bg-white/5 px-3 py-1.5 text-white shadow-sm focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6"
                                            >
                                                {availableEbayAccounts.map(
                                                    (ebayAccount) => {
                                                        return (
                                                            <option
                                                                key={
                                                                    ebayAccount.id
                                                                }
                                                                value={
                                                                    ebayAccount.id
                                                                }
                                                            >
                                                                {
                                                                    ebayAccount.name
                                                                }
                                                            </option>
                                                        );
                                                    },
                                                )}
                                            </select>
                                        </div>
                                        <div className="mt-4">
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md bg-brand px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
                                            >
                                                Switch Account
                                            </button>
                                        </div>
                                    </form>
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="flex w-full justify-center rounded-md bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default SwitchAccountModal;
