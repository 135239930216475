import React from "react";
import { useLocation } from "react-router-dom";
import LogoComponent from "@/components/logo/LogoComponent";
import { NavigationItem } from "@/types/globalTypes";
import { SidebarNavItem } from "./items/SidebarNavItem";

interface SidebarNavProps {
    navigation: NavigationItem[];
}

const SidebarNav: React.FC<SidebarNavProps> = ({ navigation }) => {
    const location = useLocation();

    return (
        <div className="hidden border-r dark:border-r-gray-200 lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-4 dark:bg-gray-800">
                <div className="mt-2 flex h-14 shrink-0 items-center">
                    <LogoComponent className="mx-auto mt-3 h-24" />
                </div>
                <nav className="flex flex-1 flex-col pl-2 pr-4 pt-6">
                    <ul role="list" className="flex flex-1 flex-col gap-y-2">
                        {navigation.map((item, index) => (
                            <SidebarNavItem
                                key={index}
                                item={item}
                                isActive={item.href === location.pathname}
                            />
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default SidebarNav;
