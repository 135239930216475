import { gql } from "@apollo/client";

export const GET_EBAY_ITEM_QUERY = gql`
    query GetEbayItem(
        $ebayItemId: ID!
        $after: String
        $before: String
        $first: Int
        $last: Int
        $offset: Int
    ) {
        ebayItem(ebayItemId: $ebayItemId) {
            id
            ebayItemId
            costOfGoodsPerItem
            createdAt
            imageUrl
            itemCondition
            itemName
            listedAt
            listedPrice
            listingType
            packingCostsPerItem
            quantity
            sourcedAt
            storageLocation
            unsold
            updatedAt
            tags {
                id
                name
                updatedAt
                createdAt
            }
            sourceLocation {
                address
                city
                country
                createdAt
                id
                name
                state
                updatedAt
                zipCode
            }
            relistedItemEvents {
                createdAt
                eventType
                id
                newValue
                previousValue
                revisedFieldName
                updatedAt
            }
            itemCategory {
                categoryId
                categoryName
                createdAt
                id
                updatedAt
            }
            itemBrand {
                createdAt
                id
                name
                updatedAt
            }
            ebaySales(
                after: $after
                before: $before
                first: $first
                last: $last
                offset: $offset
            ) {
                edges {
                    node {
                        createdAt
                        ebayFees
                        ebayOrderId
                        ebayPromotionFees
                        id
                        quantitySold
                        returned
                        shippedAt
                        shippingPrice
                        shippingCost
                        soldAt
                        soldPrice
                        updatedAt
                        profitAndRoi {
                            id
                            totalProfitLoss
                            totalReturnOnInvestment
                        }
                    }
                    cursor
                }
            }
            ebayItemEvents {
                createdAt
                eventType
                eventTime
                id
                newValue
                previousValue
                revisedFieldName
                updatedAt
                sale {
                    createdAt
                    ebayFees
                    ebayOrderId
                    ebayPromotionFees
                    id
                    quantitySold
                    returned
                    shippedAt
                    shippingPrice
                    shippingCost
                    soldAt
                    soldPrice
                    updatedAt
                    profitAndRoi {
                        id
                        totalProfitLoss
                        totalReturnOnInvestment
                    }
                }
            }
            profitAndRoi {
                id
                totalProfitLoss
                totalReturnOnInvestment
                totalSales
                totalRevenue
                totalCosts
                totalEbayFees
            }
        }
    }
`;
