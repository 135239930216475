"use client";
import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { toggleFullScreen } from "@/helpers/common/toggleFullScreen";
import {
    Bars3Icon,
    BellIcon,
    ViewfinderCircleIcon,
} from "@heroicons/react/24/outline";
import IconComponent from "@/components/icon/IconComponent";
import InitialsComponent from "@/components/dashboard/general/svgs/InitialsComponent";
import { Link } from "react-router-dom";
import { useTheme } from "@/hooks/general/useTheme";
import { useState } from "react";
import SwitchAccountModal from "@/components/dashboard/general/modals/SwitchAccountModal";
import { useAuth } from "@/hooks/auth/useAuth";
import { classNames } from "@/helpers/common/classNames";

interface TopNavProps {
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TopNav: React.FC<TopNavProps> = ({ setSidebarOpen }) => {
    const { toggleTheme } = useTheme();
    const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] =
        useState(false);
    const { user, logoutUser } = useAuth();

    const userName = user?.username || "User";
    const profileImageURL = user?.profileImageUrl || "";

    const myAccountLink = `/dashboard/account`;

    const toggleSwitchAccountModal = () => {
        setIsSwitchAccountModalOpen(!isSwitchAccountModalOpen);
    };

    return (
        <>
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b bg-white px-4 shadow-sm dark:border-b-gray-200 dark:bg-gray-800 sm:gap-x-6 sm:px-6 lg:px-8">
                <button
                    type="button"
                    className="-m-2.5 p-2.5 dark:text-white lg:hidden"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div
                    className="h-6 w-px dark:bg-white lg:hidden"
                    aria-hidden="true"
                />

                <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <form
                        className="relative flex flex-1"
                        action="#"
                        method="GET"
                    >
                        <label htmlFor="search-field" className="sr-only">
                            Search
                        </label>
                        <IconComponent
                            iconName="magnifying-glass-solid-20"
                            className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 dark:text-white"
                            aria-hidden="true"
                        />
                        <input
                            id="search-field"
                            className="block h-full w-full border-0 py-0 pl-8 pr-0 focus:ring-0 dark:bg-gray-800 dark:text-white dark:placeholder:text-white sm:text-sm"
                            placeholder="Search..."
                            type="search"
                            name="search"
                        />
                    </form>
                    {/* Separator */}
                    <div
                        className="hidden dark:bg-gray-200 lg:block lg:h-full lg:w-px"
                        aria-hidden="true"
                    />
                    <div className="flex items-center gap-x-4 lg:gap-x-6">
                        <button
                            type="button"
                            className="-m-2.5 p-2.5 dark:text-white dark:hover:text-white"
                        >
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        <button
                            type="button"
                            onClick={toggleFullScreen}
                            className="-m-2.5 hidden p-2.5 dark:text-white dark:hover:text-white md:block"
                        >
                            <span className="sr-only">Full Screen</span>

                            <ViewfinderCircleIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>

                        {/* Theme toggler */}
                        <button
                            type="button"
                            className="-m-2.5 hidden p-2.5 dark:text-white dark:hover:text-white md:block"
                            onClick={() => toggleTheme()}
                        >
                            <span className="sr-only">Toggle theme</span>
                            <IconComponent
                                iconName="light-bulb-solid"
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>

                        {/* Separator */}
                        <div
                            className="hidden dark:bg-gray-200 lg:block lg:h-full lg:w-px"
                            aria-hidden="true"
                        />

                        {/* Profile dropdown */}
                        <Menu as="div" className="relative">
                            <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                <span className="sr-only">Open user menu</span>
                                {profileImageURL ? (
                                    <span className="">
                                        <img
                                            src={profileImageURL}
                                            alt="Profile image"
                                            className="h-8 w-8 rounded-full dark:bg-gray-800"
                                        />
                                    </span>
                                ) : (
                                    <InitialsComponent name={userName} />
                                )}
                                <span className="hidden lg:flex lg:items-center">
                                    <span
                                        className="ml-4 text-sm font-semibold leading-6 dark:text-white"
                                        aria-hidden="true"
                                    >
                                        {userName}
                                    </span>
                                    <IconComponent
                                        iconName="chevron-down-solid-20"
                                        className="ml-2 h-5 w-5 dark:text-white"
                                        aria-hidden={true}
                                    />
                                </span>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute -right-3.5 z-10 mt-2.5 w-44 origin-top-right rounded-md py-2 shadow-lg ring-1 focus:outline-none dark:bg-gray-800 dark:ring-gray-800/5">
                                    <Menu.Item>
                                        <Link
                                            to={myAccountLink}
                                            className={classNames(
                                                "block px-3 py-1 text-sm leading-6 dark:text-white",
                                            )}
                                        >
                                            Account
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <button
                                            onClick={toggleSwitchAccountModal}
                                            className={classNames(
                                                "block px-3 py-1 text-sm leading-6 dark:text-white",
                                            )}
                                        >
                                            Switch eBay Account
                                        </button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link
                                            to="#"
                                            onClick={logoutUser}
                                            className={classNames(
                                                "block px-3 py-1 text-sm leading-6 dark:text-white",
                                            )}
                                        >
                                            Sign Out
                                        </Link>
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>

            <SwitchAccountModal
                isOpen={isSwitchAccountModalOpen}
                closeModal={toggleSwitchAccountModal}
            />
        </>
    );
};

export default TopNav;
