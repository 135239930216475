import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/hooks/auth/useAuth";
import { ReactNode } from "react";

interface RouteGuardProps {
    children: ReactNode;
}

const AuthGuard = ({ children }: RouteGuardProps) => {
    const {
        user,
        checkUserAuthenticated,
        checkEmailVerified,
        checkActiveSubscription,
        checkAccountInitialised,
        fetchEbayAccounts,
    } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const authenticateAndFetchAccounts = async () => {
            let authenticated = user !== null; // Assume user is authenticated if user object is not null
            let accounts = user?.ebayAccounts || [];
            let hasActiveSubscription = false;
            let isAccountInitialized = false;

            if (!authenticated) {
                // Check authentication status if not already authenticated
                authenticated = await checkUserAuthenticated();
                if (!authenticated) {
                    // Allow access to login and signup pages for unauthenticated users
                    if (location.pathname !== "/login" && location.pathname !== "/signup") {
                        navigate("/login");
                    }
                    return;
                }
            } else {
                await checkEmailVerified();
            }

            if (authenticated && user && !user.emailVerified) {
                if (location.pathname !== "/email-verification") {
                    navigate("/email-verification");
                }
                return;
            }

            if (authenticated) {
                hasActiveSubscription = await checkActiveSubscription();

                // Refetch eBay accounts to ensure we have the latest data
                accounts = await fetchEbayAccounts();
                
                // Check account initialization after refetching accounts
                isAccountInitialized = await checkAccountInitialised();

                if (location.pathname === "/signup") {
                    navigate("/dashboard"); // Redirect to the dashboard or another appropriate page
                    return;
                }

                // Updated dashboard access check
                if (location.pathname === "/dashboard") {
                    if (!hasActiveSubscription) {
                        navigate("/checkout");
                    } else if (!isAccountInitialized) {
                        navigate("/stripe/success");
                    }
                    return;
                }

                // Updated checkout and stripe/cancel access check
                if (
                    location.pathname === "/checkout" ||
                    location.pathname === "/stripe/cancel"
                ) {
                    if (hasActiveSubscription) {
                        if (isAccountInitialized) {
                            navigate("/dashboard");
                        } else {
                            navigate("/stripe/success");
                        }
                    }
                    return;
                }

                if (location.pathname === "/stripe/success") {
                    if (!hasActiveSubscription) {
                        navigate("/checkout");
                    }
                    return;
                }

                // Redirect to dashboard if user already has an eBay account and tries to access /ebay-auth
                if (location.pathname === "/ebay-auth" && accounts.length > 0) {
                    navigate("/dashboard");
                    return;
                }

                if (accounts.length === 0 && location.pathname !== "/ebay-auth" && location.pathname !== "/ebay-callback") {
                    // Redirect to eBay auth if no accounts are available after fetching
                    navigate("/ebay-auth");
                    return;
                }
            }

            // If authenticated and all checks are passed, no action needed
            // This lets the component render the children as intended
        };

        authenticateAndFetchAccounts();
    }, [
        navigate,
        checkUserAuthenticated,
        checkEmailVerified,
        checkActiveSubscription,
        checkAccountInitialised,
        fetchEbayAccounts,
        user,
        location.pathname,
    ]);

    // Show a loading spinner or some UI while checking authentication
    if (!user && location.pathname !== "/login" && location.pathname !== "/signup") return null;

    return <>{children}</>;
};

export default AuthGuard;
