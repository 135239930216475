const TasksComponent = () => {
    return (
        <div className="rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:col-span-2 sm:col-start-3 sm:row-start-2 sm:p-6">
            <dt className="text-sm font-medium text-gray-500 dark:text-white/50">
                Tasks
            </dt>
            <dd></dd>
        </div>
    );
};

export default TasksComponent;
