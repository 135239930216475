import LogoComponent from "@/components/logo/LogoComponent";
import { INITIATE_EBAY_AUTH_MUTATION } from "@/graphql/mutations/oauthMutations";
import { useMutation } from "@apollo/client/react";

export default function EbayAuthPage() {
    const [initiateEbayAuth] = useMutation(INITIATE_EBAY_AUTH_MUTATION);

    const initiateOauth = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // Type assertion to inform TypeScript about the form structure
        const form = e.currentTarget;
        const nameInput = form.elements.namedItem("name") as HTMLInputElement;

        const name = nameInput.value;

        try {
            const { data } = await initiateEbayAuth({
                variables: { name: name },
            });

            if (data.initiateOauth.loginUrl) {
                window.location.href = data.initiateOauth.loginUrl;
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <LogoComponent className="mx-auto h-20 w-auto" />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
                    To use this application, you must first authenticate with
                    eBay. Click the button below to get started.
                </h2>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={initiateOauth}>
                        <div>
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium leading-6 dark:text-white"
                            >
                                Ebay Account Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="name"
                                    name="name"
                                    type="name"
                                    autoComplete="name"
                                    required
                                    className="m:text-sm block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-brand focus:ring-2 focus:ring-inset focus:ring-brand focus-visible:ring-brand dark:ring-white/10 dark:focus:ring-brand dark:focus-visible:ring-brand sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md border border-transparent bg-brand px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-brand/80 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                            >
                                Authenticate with eBay
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
