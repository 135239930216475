import { useState, useEffect } from "react";
import StatsComponent from "@/components/dashboard/dash/widgets/StatsComponent";
import GraphComponent from "@/components/dashboard/dash/widgets/GraphComponent";
import MostProfitableProductComponent from "@/components/dashboard/dash/widgets/MostProfitableProductComponent";
import TasksComponent from "@/components/dashboard/dash/widgets/TasksComponent";
import useEbaySalesProfitsAndRoi from "@/hooks/sold/useEbaySalesProfitsAndRoi";
import useDashboardStats from "@/hooks/sold/useDashboardStats";
import { SaleData } from "@/types/globalTypes";
import CustomDateRangePicker from "@/components/dashboard/dash/widgets/DatePickerComponent";
import {
    differenceInDays,
    differenceInMonths,
    startOfDay,
    startOfHour,
    startOfWeek,
    startOfMonth,
    endOfDay,
    format,
    eachDayOfInterval,
    eachHourOfInterval,
    eachWeekOfInterval,
    eachMonthOfInterval,
    eachYearOfInterval,
} from "date-fns";

export default function DashboardPage() {
    const { data, loading, error, filters, setFilters, applyFilters } = useEbaySalesProfitsAndRoi();
    const { data: statsData, loading: statsLoading, error: statsError, filters: statsFilters, setFilters: setStatsFilters, applyFilters: applyStatsFilters } = useDashboardStats();
    const [aggregatedData, setAggregatedData] = useState<Record<string, number>>({});

    useEffect(() => {
        if (data) {
            const aggregated = aggregateData(data, new Date(filters.ebaySaleSoldAtGte), new Date(filters.ebaySaleSoldAtLte));
            setAggregatedData(aggregated);
        }
    }, [data, filters, statsData, statsFilters]);

    const aggregateData = (salesData: SaleData[], startDate: Date, endDate: Date) => {
        const diffInDays = differenceInDays(endDate, startDate);
        const diffInMonths = differenceInMonths(endDate, startDate);

        let intervals: Date[] = [];
        let aggregationInterval: (date: Date) => Date;
        let formatString: string;

        if (diffInDays <= 1) {
            // Aggregate by hour
            intervals = eachHourOfInterval({ start: startOfDay(startDate), end: endOfDay(endDate) });
            aggregationInterval = (date) => startOfHour(date);
            formatString = "dd/MM/yyyy HH:00";
        } else if (diffInDays <= 30) {
            // Aggregate by day
            intervals = eachDayOfInterval({ start: startDate, end: endDate });
            aggregationInterval = (date) => startOfDay(date);
            formatString = "dd/MM/yyyy";
        } else if (diffInDays <= 90) {
            // Aggregate by week
            intervals = eachWeekOfInterval({ start: startDate, end: endDate });
            aggregationInterval = (date) => startOfWeek(date);
            formatString = "'Week of' dd/MM/yyyy";
        } else if (diffInMonths <= 12) {
            // Aggregate by month
            intervals = eachMonthOfInterval({ start: startDate, end: endDate });
            aggregationInterval = (date) => startOfMonth(date);
            formatString = "MMMM yyyy";
        } else {
            // Aggregate by year
            intervals = eachYearOfInterval({ start: startDate, end: endDate });
            aggregationInterval = (date) => new Date(date.getFullYear(), 0, 1);
            formatString = "yyyy";
        }

        const aggregated: Record<string, number> = {};

        intervals.forEach((interval) => {
            const formattedDate = format(interval, formatString);
            aggregated[formattedDate] = 0;
        });

        salesData.forEach((sale) => {
            const saleDate = aggregationInterval(new Date(sale.ebaySale.soldAt));
            const formattedSaleDate = format(saleDate, formatString);
            const totalProfitLoss = parseFloat(sale.totalProfitLoss as string);
            if (!isNaN(totalProfitLoss)) {
                aggregated[formattedSaleDate] += totalProfitLoss;
            }
        });

        console.log("Aggregated Data:", aggregated);
        return aggregated;
    };

    if (loading || statsLoading) {
        return <div>Loading...</div>;
    }
    if (error || statsError) {
        return <div>Error...</div>;
    }



    return (
        <>
            <CustomDateRangePicker
                initialStartDate={new Date(filters.ebaySaleSoldAtGte)}
                initialEndDate={new Date(filters.ebaySaleSoldAtLte)}
                setFilters={setFilters}
                applyFilters={applyFilters}
                setStatsFilters={setStatsFilters}
                applyStatsFilters={applyStatsFilters}
            />
            <StatsComponent statsData={statsData} />

            <dl className="mt-5 grid gap-5 sm:grid-cols-4 sm:grid-rows-2">
                <GraphComponent aggregatedData={aggregatedData} />
                <MostProfitableProductComponent />
                <TasksComponent />
            </dl>
        </>
    );
}
