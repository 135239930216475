import { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "@/hooks/auth/useAuth"; // Ensure the path is correct
import { Suspense } from "react";

const INITIATE_OAUTH_GET_ACCESS_TOKEN = gql`
    mutation InitiateOauthGetAccessToken($name: String!, $code: String!) {
        initiateOauthGetAccessToken(code: $code, name: $name) {
            success
            ebayAccount {
                id
                name
                isDefault
                isActive
            }
        }
    }
`;

export default function EbayCallbackPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { addEbayAccount } = useAuth();

    const [initiateOauthGetAccessToken, { loading, error }] = useMutation(
        INITIATE_OAUTH_GET_ACCESS_TOKEN,
    );

    useEffect(() => {
        const handleOAuth = async () => {
            const code = searchParams.get("code");
            const name = searchParams.get("state"); // Assuming 'state' is used for the eBay account name

            if (!code) {
                navigate("/login"); // Redirect if no code is provided
                return;
            }

            try {
                const { data } = await initiateOauthGetAccessToken({
                    variables: { code, name },
                });

                if (data.initiateOauthGetAccessToken.success) {
                    const ebayAccount =
                        data.initiateOauthGetAccessToken.ebayAccount;
                    addEbayAccount(ebayAccount); // Update user context with the new eBay account

                    navigate("/checkout");
                } else {
                    navigate("/error"); // Redirect to an error page or handle errors appropriately
                }
            } catch (error) {
                console.error("OAuth error:", error);
                navigate("/error"); // Redirect to an error page or handle errors appropriately
            }
        };

        handleOAuth();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error occurred: {error.message}</div>;

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div>
                <h1>Redirecting...</h1>
            </div>
        </Suspense>
    );
}
