import React from "react";
import LogoComponent from "@/components/logo/LogoComponent";
import { useAuth } from "@/hooks/auth/useAuth";

const SuccessPage: React.FC = () => {
    const { logoutUser } = useAuth();

  const handleLearnMore = () => {
    window.location.href = "https://fliplytics.co.uk"; // Redirect to the external About page
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-2 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <LogoComponent className="mx-auto h-20 w-auto" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white">
          Thank You for Signing Up to FlipLytics!
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="bg-white dark:bg-gray-800 p-8 pb-3 rounded-lg shadow-md w-full">
          <p className="mb-6 text-center text-lg dark:text-white">
            We are now syncing your eBay account and previous sales data. This process may can range from a few minutes to a few hours depending on the amount of data.
            You will receive an email once the sync is complete, and you can then access your account.
          </p>

          <div className="flex justify-center mb-6">
            <button
              onClick={handleLearnMore}
              className="px-4 py-2 rounded-md focus:outline-none bg-gray-200 text-gray-900 hover:bg-gray-300 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
            >
              Learn More
            </button>
          </div>

          <p className="text-sm text-center dark:text-gray-400">
            Need help? <a href="https://fliplytics.co.uk/contact" className="font-semibold text-brand hover:text-brand-light">Contact our support team</a>.
          </p>
          <p className="text-sm text-center dark:text-gray-400 mt-3">
            <a href="#" onClick={logoutUser} className="font-semibold text-brand hover:text-brand-light">Sign out</a>
            </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
