import { createContext, useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { UserData, AuthContextType, EbayAccount } from "@/types/globalTypes";

// Import your GraphQL queries/mutations here
import {
    LOGIN_USER_MUTATION,
    LOGOUT_USER_MUTATION,
    SIGNUP_USER_MUTATION,
} from "@/graphql/mutations/authMutations";
import {
    USER_AUTHENTICATED_QUERY,
    EBAY_ACCOUNTS_ACCESS_QUERY,
    CHECK_EMAIL_VERIFIED_QUERY,
    CHECK_EBAY_ACCOUNT_INITIALISED_QUERY,
} from "@/graphql/queries/authQueries";
import { GET_SUBSCRIPTION_QUERY } from "@/graphql/queries/paymentQueries";

export const AuthContext = createContext<AuthContextType>({
    user: null,
    loginUser: async () => {},
    logoutUser: async () => {},
    signupUser: async () => {},
    checkUserAuthenticated: async () => false,
    checkEmailVerified: async () => false,
    checkActiveSubscription: async () => false,
    checkAccountInitialised: async () => false,
    fetchEbayAccounts: async () => [],
    addEbayAccount: async () => {},
    setActiveEbayAccount: () => {},
});

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [user, setUser] = useState<UserData | null>(null);
    const client = useApolloClient();

    useEffect(() => {
        const initializeAuth = async () => {
            const userData = localStorage.getItem("userData");
            if (userData) {
                try {
                    const authenticated = await checkUserAuthenticated();
                    if (authenticated) {
                        setUser(JSON.parse(userData));
                    } else {
                        // Clear the user data if not authenticated
                        localStorage.removeItem("userData");
                        setUser(null);
                    }
                } catch (error) {
                    console.error("Error during authentication check:", error);
                    localStorage.removeItem("userData");
                    setUser(null);
                }
            }
        };

        initializeAuth();
    }, []);

    const loginUser = async (email: string, password: string) => {
        try {
            console.log("Attempting to log in with:", { email, password });
    
            const { data } = await client.mutate({
                mutation: LOGIN_USER_MUTATION,
                variables: { email, password },
            });
    
            console.log("Login mutation response:", data);
    
            if (data.loginUser.success) {
                const userData = {
                    username: `${data.loginUser.user.firstName} ${data.loginUser.user.lastName}`,
                    ebayAccounts: data.loginUser.user.ebayAccounts,
                    activeEbayAccount:
                        data.loginUser.user.ebayAccounts.find(
                            (account: EbayAccount) => account.isDefault,
                        ) || null,
                    profileImageUrl: data.loginUser.user.profileImageUrl,
                    emailVerified: data.loginUser.user.emailVerified,
                };
    
                console.log("Setting user data in state and localStorage:", userData);
    
                localStorage.setItem("userData", JSON.stringify(userData));
                setUser(userData);
            } else {
                console.warn("Login failed:", data.loginUser.message);
            }
    
            return data.loginUser;
        } catch (error) {
            console.error("Login error:", error);
            throw error;
        }
    };
    

    const logoutUser = async () => {
        try {
            await client.mutate({
                mutation: LOGOUT_USER_MUTATION,
            });
            localStorage.removeItem("userData");
            setUser(null);
        } catch (error) {
            console.error("Logout error:", error);
            throw error;
        }
    };

    const signupUser = async (email: string, password: string, firstName: string, lastName: string) => {
        try {
            const { data } = await client.mutate({
                mutation: SIGNUP_USER_MUTATION,
                variables: { email, password, firstName, lastName },
            });
            if (data.signupUser.success) {
                const userData = {
                    username: `${data.signupUser.user.firstName} ${data.signupUser.user.lastName}`,
                    ebayAccounts: data.signupUser.user.ebayAccounts,
                    activeEbayAccount:
                        data.signupUser.user.ebayAccounts.find(
                            (account: EbayAccount) => account.isDefault,
                        ) || null,
                    profileImageUrl: data.signupUser.user.profileImageUrl,
                    emailVerified: data.signupUser.user.emailVerified,
                };
                localStorage.setItem("userData", JSON.stringify(userData));
                setUser(userData);
            }
            return data.signupUser;
        } catch (error) {
            console.error("Sign-up error:", error);
            throw error;
        }
    };

    const checkEmailVerified = async () => {
        try {
            const { data } = await client.query({
                query: CHECK_EMAIL_VERIFIED_QUERY,
            });
            
            localStorage.setItem("userData", JSON.stringify({
                ...user,
                emailVerified: data.emailVerified.emailVerified,
            }));

            return data.emailVerified.emailVerified;
        } catch (error) {
            console.error("Email verification check error:", error);
            return false;
        }
    };

    const checkActiveSubscription = async () => {
        try {
            const { data } = await client.query({
                query: GET_SUBSCRIPTION_QUERY,
            });
            
            const subscription = data.subscription;

            console.log("Subscription data:", subscription);

            if (!subscription) {
                return false;
            }
            const subscriptionStatus = data.subscription.status;
            const currentPeriodEnd = new Date(data.subscription.currentPeriodEnd);

            // Check if the subscription is active or in a trial period and if the current period end date is in the future.
            // We need the status to be 'active' or 'trialing' and the current period end date to be in the future.
            return (
                (subscriptionStatus === "active" || subscriptionStatus === "trialing") &&
                currentPeriodEnd > new Date()
            );
        } catch (error) {
            console.error("Subscription check error:", error);
            return false;
        }
    };

    const checkAccountInitialised = async () => {
        try {
            const { data } = await client.query({
                query: CHECK_EBAY_ACCOUNT_INITIALISED_QUERY,
            });
            return data.ebayAccountIsInitialised;
        } catch (error) {
            console.error("Account initialization check error:", error);
            return false;
        }
    }

    const checkUserAuthenticated = async () => {
        try {
            const { data } = await client.query({
                query: USER_AUTHENTICATED_QUERY,
            });
            return data.isAuthenticated;
        } catch (error) {
            console.error("Authentication check error:", error);
            return false;
        }
    };

    const [lastFetched, setLastFetched] = useState<number | null>(null);

    const fetchEbayAccounts = async (): Promise<EbayAccount[]> => {
        const now = Date.now();
        
        // If data was fetched less than 5 minutes ago, return cached accounts
        if (lastFetched && (now - lastFetched < 5 * 60 * 1000)) {
            return user?.ebayAccounts || [];
        }

        try {
            const { data } = await client.query({
                query: EBAY_ACCOUNTS_ACCESS_QUERY,
                fetchPolicy: "network-only",
            });

            if (user) {
                const updatedUser = {
                    ...user,
                    ebayAccounts: data.ebayAccountsCanAccess,
                    activeEbayAccount: data.ebayAccountsCanAccess.find(
                        (account: EbayAccount) => account.isDefault
                    ) || null,
                };
                localStorage.setItem("userData", JSON.stringify(updatedUser));
                setUser(updatedUser);
            }

            // Update the timestamp when data was last fetched
            setLastFetched(now);

            return data.ebayAccountsCanAccess || [];
        } catch (error) {
            console.error("Fetching eBay accounts error:", error);
            return [];
        }
    };

    const addEbayAccount = async (newEbayAccount: EbayAccount) => {
        if (!user) {
            console.warn("No user is logged in to add an eBay account");
            return;
        }

        const updatedEbayAccounts = [
            ...(user.ebayAccounts || []),
            newEbayAccount,
        ];
        const updatedUser = { ...user, ebayAccounts: updatedEbayAccounts };

        setUser(updatedUser);
        localStorage.setItem("userData", JSON.stringify(updatedUser));
    };

    const setActiveEbayAccount = (ebayAccountId: string) => {
        if (!user) {
            console.warn("No user is logged in to set an active eBay account");
            return;
        }

        const updatedUser = {
            ...user,
            activeEbayAccount:
                user.ebayAccounts.find(
                    (account: EbayAccount) => account.id === ebayAccountId,
                ) || null,
        };
        setUser(updatedUser);
        localStorage.setItem("userData", JSON.stringify(updatedUser));
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                loginUser,
                logoutUser,
                signupUser,
                checkUserAuthenticated,
                checkEmailVerified,
                checkActiveSubscription,
                checkAccountInitialised,
                fetchEbayAccounts,
                addEbayAccount,
                setActiveEbayAccount,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
