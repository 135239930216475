import TagsTableComponent from "@/components/dashboard/manage/tags/TagsTableComponent";

const tagsColumns = [
    {
        key: "name",
        label: "Tag Name",
        render: (value: string) => value,
    },
];

export default function TagsPage() {
    return (
        <>
            <TagsTableComponent columns={tagsColumns} />
        </>
    );
}
