import React from 'react';
import { formatDate } from '@/helpers/common/formatDate';

interface ProductDetailsComponentProps {
    product: any;
}

// Function that works out how long a product has been listed for based off the listed date to now or the sold date if it has been sold
const listedFor = (listedDate: string, soldDate: string) => {
    const listed = new Date(listedDate);
    const sold = new Date(soldDate);

    if (soldDate) {
        return Math.floor((sold.getTime() - listed.getTime()) / (1000 * 60 * 60 * 24));
    }

    return Math.floor((new Date().getTime() - listed.getTime()) / (1000 * 60 * 60 * 24));
};

const ProductDetailsComponent: React.FC<ProductDetailsComponentProps> = ({ product }) => {
    return (
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:p-6 sm:col-span-2">
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white">
                <img src={product?.imageUrl} alt={product?.itemName} className="mb-4" />
                <dl className="mt-5 grid grid-cols-2 gap-5">
                    <div className="overflow-hidden rounded-lg bg-white px-4 py-1 shadow dark:bg-gray-700 sm:p-2">
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            Item ID
                        </dt>
                        <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900 dark:text-white">
                            {product?.ebayItemId}
                        </dd>
                    </div>
                    <div className="overflow-hidden rounded-lg bg-white px-4 py-1 shadow dark:bg-gray-700 sm:p-2">
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            Listed For
                        </dt>
                        <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900 dark:text-white">
                            {listedFor(product?.listedAt, product?.ebaySales?.edges[0]?.node?.soldAt)} days
                        </dd>
                    </div>
                    <div className="overflow-hidden rounded-lg bg-white px-4 py-1 shadow dark:bg-gray-700 sm:p-2">
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            Quantity
                        </dt>
                        <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900 dark:text-white">
                            {product?.quantity}
                        </dd>
                    </div>
                    <div className="overflow-hidden rounded-lg bg-white px-4 py-1 shadow dark:bg-gray-700 sm:p-2">
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            Purchase Date
                        </dt>
                        <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900 dark:text-white">
                            {product?.sourcedAt ? formatDate(product?.sourcedAt) : 'N/A'}
                        </dd>
                    </div>
                    <div className="overflow-hidden rounded-lg bg-white px-4 py-1 shadow dark:bg-gray-700 sm:p-2">
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            Cost of Goods Per Item
                        </dt>
                        <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900 dark:text-white">
                            {product?.costOfGoodsPerItem ? `£${product?.costOfGoodsPerItem}` : 'N/A'}
                        </dd>
                    </div>
                    <div className="overflow-hidden rounded-lg bg-white px-4 py-1 shadow dark:bg-gray-700 sm:p-2">
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            Packing Costs Per Item
                        </dt>
                        <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900 dark:text-white">
                            {product?.packingCostsPerItem ? `£${product?.packingCostsPerItem}` : 'N/A'}
                        </dd>
                    </div>
                    <div className="overflow-hidden rounded-lg bg-white px-4 py-1 shadow dark:bg-gray-700 sm:p-2">
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            Source Location
                        </dt>
                        <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900 dark:text-white">
                            {product?.sourceLocation?.name || 'N/A'}
                        </dd>
                    </div>
                    <div className="overflow-hidden rounded-lg bg-white px-4 py-1 shadow dark:bg-gray-700 sm:p-2">
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            Storage Location
                        </dt>
                        <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900 dark:text-white">
                            {product?.storageLocation || 'N/A'}
                        </dd>
                    </div>
                    <div className="overflow-hidden rounded-lg bg-white px-4 py-1 shadow dark:bg-gray-700 sm:p-2">
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            Brand
                        </dt>
                        <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900 dark:text-white">
                            {product?.itemBrand.name || 'N/A'}
                        </dd>
                    </div>
                </dl>
            </dd>
        </div>
    );
};

export default ProductDetailsComponent;
