import React, { Fragment, useRef, useEffect, useState, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LogoComponent from "@/components/logo/LogoComponent";
import { useScreenshot } from "@/hooks/general/useScreenshot";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    product: any;
}

const ScreenshotProductModal: React.FC<ModalProps> = ({ isOpen, closeModal, product }) => {
    const ref = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);
    const { image, downloadScreenshot } = useScreenshot();
    const [isPortrait, setIsPortrait] = useState<boolean>(false);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);

    const handleImageLoad = useCallback(() => {
        if (imageRef.current) {
            const { naturalWidth, naturalHeight } = imageRef.current;
            setIsPortrait(naturalHeight > naturalWidth);
            setImageLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (isOpen) {
            setImageLoaded(false);
            if (imageRef.current && imageRef.current.complete) {
                handleImageLoad();
            }
        }
    }, [isOpen, handleImageLoad]);

    const handleScreenshot = async () => {
        if (ref.current) {
            try {
                await downloadScreenshot(ref.current, 'screenshot.jpg', { type: 'jpeg', quality: 1 });
                console.log('Screenshot downloaded');
            } catch (err) {
                console.error('Screenshot error:', err);
            }
        }
    };

    const costOfGoodsPerItem = product?.costOfGoodsPerItem || 0;
    const totalRevenue = product?.profitAndRoi?.totalRevenue || 0;
    const totalProfit = product?.profitAndRoi?.totalProfitLoss || 0;
    const totalCostOfGoods = product?.profitAndRoi?.totalCosts || 0;
    const totalEbayFees = product?.profitAndRoi?.totalEbayFees || 0;
    const totalROI = parseFloat(product?.profitAndRoi?.totalReturnOnInvestment || 0).toFixed(1);

    const stats = [
        {
            name: "Cost of Goods",
            stat: "£" + costOfGoodsPerItem
        },
        {
            name: "Revenue",
            stat: "£" + totalRevenue
        },
        {
            name: "eBay Fees",
            stat: "£" + totalEbayFees
        },
        {
            name: "Profit",
            stat: "£" + totalProfit
        },
        {
            name: "Total Costs",
            stat: "£" + totalCostOfGoods
        },
        {
            name: "ROI",
            stat: totalROI + "%"
        }
    ];

    const getStatClass = (name: string, value: number) => {
        if (name === "Revenue") return 'text-green-500';
        if (name === "Cost of Goods" || name === "eBay Fees" || name === "Total Costs") return 'text-red-500';
        return value >= 0 ? 'text-green-500' : 'text-red-500';
    };

    return (
        <Transition show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="border-2 fixed inset-0 bg-white dark:bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="border-2 border-gray-900 dark:border-0 relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-900 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 lg:pt-4">
                                <div className="mb-3">
                                    <div className="">
                                        <dd className="pt-4 px-4 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white bg-white dark:bg-gray-900" ref={ref}>
                                            <h3 className="text-lg font-semibold leading-6 text-gray-900 dark:text-white mb-4 text-center">
                                                {product?.itemName}
                                            </h3>
                                            <div className="grid grid-cols-2 gap-5">
                                                <div>
                                                    <div className="flex justify-center items-center">
                                                        <img
                                                            ref={imageRef}
                                                            src={product?.imageUrl}
                                                            alt={product?.itemName}
                                                            className="mb-4"
                                                            onLoad={handleImageLoad}
                                                        />
                                                    </div>
                                                    {!isPortrait && imageLoaded && (
                                                        <div className="mt-4 mb-4 flex justify-center">
                                                            <LogoComponent width={100} height={100} />
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <dl className="grid grid-cols-2 gap-5">
                                                        {stats.map((item, index) => (
                                                            <div
                                                                key={item.name}
                                                                className={`overflow-hidden rounded-lg bg-white p-5 shadow dark:bg-gray-800 sm:p-6 ${index === stats.length - 1 || index === stats.length - 2 ? 'mb-5' : ''}`}
                                                            >
                                                                <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50 text-center">
                                                                    {item.name}
                                                                </dt>
                                                                <dd className={`mt-1 text-3xl font-semibold tracking-tight text-center ${item.name === "Profit" || item.name === "ROI" ? getStatClass(item.name, parseFloat(item.stat.replace(/[£%]/g, ''))) : getStatClass(item.name, 0)}`}>
                                                                    {item.stat}
                                                                </dd>
                                                            </div>
                                                        ))}
                                                    </dl>
                                                    {isPortrait && imageLoaded && (
                                                        <div className="mt-4 flex justify-center">
                                                            <LogoComponent width={100} height={100} />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </dd>
                                        <div className="flex gap-4">
                                            <button
                                                type="button"
                                                className="border-2 border-gray-900 dark:border-0 flex-1 justify-center rounded-md dark:bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 dark:text-white shadow-sm dark:hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
                                                onClick={handleScreenshot}
                                            >
                                                Screenshot
                                            </button>
                                            <button
                                                type="button"
                                                className="border-2 border-gray-900 dark:border-0 flex-1 justify-center rounded-md dark:bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 dark:text-white shadow-sm dark:hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
                                                onClick={closeModal}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        {image && <img src={image} alt="Screenshot" style={{ display: 'none' }} />}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ScreenshotProductModal;
