import React, { useState, useEffect, useRef } from "react";
import { format, parseISO, startOfDay, endOfDay } from "date-fns";

interface DateRangePickerProps {
    initialStartDate: Date;
    initialEndDate: Date;
    setFilters: (name: string, value: any) => void;
    applyFilters: () => void;
    setStatsFilters: (name: string, value: any) => void;
    applyStatsFilters: () => void;
}

const presets = [
    { text: "Today", start: startOfDay(new Date()), end: endOfDay(new Date()) },
    { text: "Yesterday", start: startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))), end: endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))) },
    { text: "Last 7 Days", start: startOfDay(new Date(new Date().setDate(new Date().getDate() - 7))), end: endOfDay(new Date()) },
    { text: "Last 30 Days", start: startOfDay(new Date(new Date().setDate(new Date().getDate() - 30))), end: endOfDay(new Date()) },
    { text: "Last 3 Months", start: startOfDay(new Date(new Date().setMonth(new Date().getMonth() - 3))), end: endOfDay(new Date()) },
    { text: "Last 6 Months", start: startOfDay(new Date(new Date().setMonth(new Date().getMonth() - 6))), end: endOfDay(new Date()) },
    { text: "This Month", start: startOfDay(new Date(new Date().setDate(1))), end: endOfDay(new Date()) },
    { text: "Last Month", start: startOfDay(new Date(new Date().setMonth(new Date().getMonth() - 1, 1))), end: endOfDay(new Date(new Date().setMonth(new Date().getMonth(), 0))) },
    { text: "This Year", start: startOfDay(new Date(new Date().setMonth(0, 1))), end: endOfDay(new Date()) },
    { text: "Last Year", start: startOfDay(new Date(new Date().setFullYear(new Date().getFullYear() - 1, 0, 1))), end: endOfDay(new Date(new Date().setFullYear(new Date().getFullYear() - 1, 11, 31))) },
    { text: "Last 5 Years", start: startOfDay(new Date(new Date().setFullYear(new Date().getFullYear() - 5))), end: endOfDay(new Date()) },
    { text: "All Time", start: startOfDay(new Date(1970, 0, 1)), end: endOfDay(new Date()) },
];

const CustomDateRangePicker: React.FC<DateRangePickerProps> = ({ initialStartDate, initialEndDate, setFilters, applyFilters, setStatsFilters, applyStatsFilters }) => {
    const [startDate, setStartDate] = useState<Date>(initialStartDate);
    const [endDate, setEndDate] = useState<Date>(initialEndDate);
    const [tempStartDate, setTempStartDate] = useState<string>(format(initialStartDate, "yyyy-MM-dd"));
    const [tempEndDate, setTempEndDate] = useState<string>(format(initialEndDate, "yyyy-MM-dd"));
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const handleApplyClick = () => {
        const parsedStartDate = parseISO(tempStartDate);
        const parsedEndDate = parseISO(tempEndDate);

        const formattedStartDate = startOfDay(parsedStartDate).toISOString();
        const formattedEndDate = endOfDay(parsedEndDate).toISOString();

        setStartDate(parsedStartDate);
        setEndDate(parsedEndDate);
        setFilters("ebaySaleSoldAtGte", formattedStartDate);
        setFilters("ebaySaleSoldAtLte", formattedEndDate);
        setStatsFilters("ebaySaleSoldAtGte", formattedStartDate);
        setStatsFilters("ebaySaleSoldAtLte", formattedEndDate);
        setStatsFilters("ebayItemListedAtGte", formattedStartDate);
        setStatsFilters("ebayItemListedAtLte", formattedEndDate);
        applyStatsFilters();
        applyFilters();
        setIsOpen(false);
    };

    const handlePresetClick = (preset: { start: Date, end: Date }) => {
        setTempStartDate(format(preset.start, "yyyy-MM-dd"));
        setTempEndDate(format(preset.end, "yyyy-MM-dd"));
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>, setDate: React.Dispatch<React.SetStateAction<string>>) => {
        const parsedDate = parseISO(e.target.value);
        if (!isNaN(parsedDate.getTime())) {
            setDate(e.target.value);
        } else {
            // Handle invalid date input
            setDate(format(new Date(), "yyyy-MM-dd"));
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block text-left" ref={ref}>
            <button
                className="inline-flex justify-between items-center w-full px-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
            >
                {format(startDate, "dd/MM/yyyy")} - {format(endDate, "dd/MM/yyyy")}
                <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
            </button>

            {isOpen && (
                <div className="origin-top-right absolute left-0 mt-2 w-96 rounded-md shadow-lg bg-white dark:bg-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="py-1 px-4">
                        <div className="date-picker flex gap-2 mb-4">
                            <div className="flex flex-col">
                                <label className="text-gray-700 dark:text-gray-300 mb-1">Start Date</label>
                                <input
                                    type="date"
                                    value={tempStartDate}
                                    onChange={e => setTempStartDate(e.target.value)}
                                    onBlur={e => handleBlur(e, setTempStartDate)}
                                    className="p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label className="text-gray-700 dark:text-gray-300 mb-1">End Date</label>
                                <input
                                    type="date"
                                    value={tempEndDate}
                                    onChange={e => setTempEndDate(e.target.value)}
                                    onBlur={e => handleBlur(e, setTempEndDate)}
                                    className="p-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                                />
                            </div>
                        </div>
                        <div className="presets grid grid-cols-2 gap-2 mb-4">
                            {presets.map(preset => (
                                <button
                                    key={preset.text}
                                    onClick={() => handlePresetClick(preset)}
                                    className="px-4 py-2 bg-blue-500 dark:text-white text-gray-700 rounded-lg hover:bg-blue-600 transition"
                                >
                                    {preset.text}
                                </button>
                            ))}
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={handleApplyClick}
                                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition"
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomDateRangePicker;
