import { gql } from "@apollo/client";

export const USER_AUTHENTICATED_QUERY = gql`
    query UserAuthenticated {
        isAuthenticated
    }
`;

export const EBAY_ACCOUNTS_ACCESS_QUERY = gql`
    query EbayAccountsCanAccessQuery {
        ebayAccountsCanAccess {
            id
            name
            isDefault
            isActive
            initialised
        }
    }
`;

export const CHECK_EMAIL_VERIFIED_QUERY = gql`
    query CheckEmailVerified {
        emailVerified {
            emailVerified
            message
            status
        }
    }
`;

export const CHECK_EBAY_ACCOUNT_INITIALISED_QUERY = gql`
query CheckEbayAccountInitialisedQuery {
  ebayAccountIsInitialised
}
`;