import { gql } from "@apollo/client";

export const UPDATE_EBAY_ITEM_MUTATION = gql`
    mutation UpdateEbayItemMutation(
        $itemId: ID!
        $imageUrl: String
        $itemCategoryId: ID
        $itemCondition: Int
        $itemName: String
        $listedPrice: Decimal
        $listingType: String
        $packingCostsPerItem: Decimal
        $quantity: Int
        $sourcedAt: DateTime
        $tagIds: [ID]
        $sourceLocationId: ID
        $storageLocation: String
        $costOfGoodsPerItem: Decimal
        $itemBrandId: ID
    ) {
        updateEbayItem(
            itemId: $itemId
            imageUrl: $imageUrl
            itemBrandId: $itemBrandId
            itemCategoryId: $itemCategoryId
            itemCondition: $itemCondition
            itemName: $itemName
            listedPrice: $listedPrice
            listingType: $listingType
            packingCostsPerItem: $packingCostsPerItem
            quantity: $quantity
            sourcedAt: $sourcedAt
            tagIds: $tagIds
            sourceLocationId: $sourceLocationId
            storageLocation: $storageLocation
            costOfGoodsPerItem: $costOfGoodsPerItem
        ) {
            item {
                id
                costOfGoodsPerItem
                createdAt
                ebayItemId
                imageUrl
                itemCondition
                itemName
                listedAt
                listedPrice
                listingType
                packingCostsPerItem
                quantity
                sourcedAt
                storageLocation
                unsold
                updatedAt
                profitAndRoi {
                    id
                    totalProfitLoss
                    totalReturnOnInvestment
                    totalSales
                    totalRevenue
                    totalCosts
                }
                tags {
                    createdAt
                    id
                    name
                    updatedAt
                }
                sourceLocation {
                    address
                    city
                    country
                    createdAt
                    id
                    name
                    state
                    updatedAt
                    zipCode
                }
                itemCategory {
                    categoryId
                    categoryName
                    createdAt
                    id
                    updatedAt
                }
                itemBrand {
                    createdAt
                    id
                    name
                    updatedAt
                }
                ebaySales {
                    edges {
                        node {
                            createdAt
                            ebayFees
                            ebayOrderId
                            ebayPromotionFees
                            id
                            quantitySold
                            returned
                            shippedAt
                            shippingCost
                            shippingPrice
                            soldAt
                            soldPrice
                            updatedAt
                            profitAndRoi {
                                id
                                totalProfitLoss
                                totalReturnOnInvestment
                            }
                        }
                    }
                }
                ebayItemEvents {
                    createdAt
                    eventTime
                    eventType
                    id
                    newValue
                    previousValue
                    revisedFieldName
                    updatedAt
                    sale {
                        createdAt
                        ebayFees
                        ebayOrderId
                        ebayPromotionFees
                        id
                        quantitySold
                        returned
                        shippedAt
                        shippingCost
                        shippingPrice
                        soldAt
                        soldPrice
                        updatedAt
                        profitAndRoi {
                            id
                            totalProfitLoss
                            totalReturnOnInvestment
                        }
                    }
                }
            }
        }
    }
`;

export const UPDATE_EBAY_SALE_MUTATION = gql`
    mutation UpdateEbaySaleMutation(
        $saleId: ID!
        $ebayFees: Decimal
        $ebayOrderId: String
        $ebayPromotionFees: Decimal
        $quantitySold: Int
        $returned: Boolean
        $shippedAt: DateTime
        $shippingCost: Decimal
        $shippingPrice: Decimal
        $soldAt: DateTime
        $soldPrice: Decimal
    ) {
        updateEbaySale(
            saleId: $saleId
            ebayFees: $ebayFees
            ebayOrderId: $ebayOrderId
            ebayPromotionFees: $ebayPromotionFees
            quantitySold: $quantitySold
            returned: $returned
            shippedAt: $shippedAt
            shippingCost: $shippingCost
            shippingPrice: $shippingPrice
            soldAt: $soldAt
            soldPrice: $soldPrice
        ) {
            sale {
                id
                createdAt
                ebayFees
                ebayOrderId
                ebayPromotionFees
                quantitySold
                returned
                shippedAt
                shippingCost
                soldAt
                shippingPrice
                soldPrice
                updatedAt
                ebayItem {
                    id
                }
                profitAndRoi {
                    id
                    totalProfitLoss
                    totalReturnOnInvestment
                }
            }
        }
    }
`;

export const CREATE_EBAY_ITEM_TAG_MUTATION = gql`
    mutation CreateEbayItemTagMutation($name: String!) {
        createEbayItemTag(name: $name) {
            success
            tag {
                id
                name
                createdAt
                updatedAt
            }
        }
    }
`;

export const CREATE_SOURCE_LOCATION_MUTATION = gql`
    mutation CreateSourceLocationMutation(
        $address: String
        $city: String
        $country: String
        $name: String!
        $state: String
        $zipCode: String
    ) {
        createSourceLocation(
            name: $name
            address: $address
            city: $city
            state: $state
            zipCode: $zipCode
            country: $country
        ) {
            success
            sourceLocation {
                id
                name
                address
                city
                state
                zipCode
                country
                createdAt
                updatedAt
            }
        }
    }
`;
