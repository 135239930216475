import InventoryMissingDataEbayItemsTableComponent from "@/components/dashboard/inventory/InventoryMissingDataEbayItemsTableComponent";
import { decodeBase64Id } from "@/helpers/common/decodeBase64Id";
import { Link } from "react-router-dom";

const inventoryMissingDataProductsColumns = [
    {
        key: "itemName",
        label: "Product",
        render: (value: string, item?: any) => (
            <Link to={`/dashboard/products/${decodeBase64Id(item.id)}`} className="text-blue-500 hover:underline">
                {value}
            </Link>
        ),
    },
    {
        key: "listedAt",
        label: "Listed",
        render: (value: string) => (value ? new Date(value).toLocaleDateString() : ''),
    },
    {
        key: "costOfGoodsPerItem",
        label: "Cost",
        render: (value: number) => (value != null ? `£${value}` : ''),
    },
    {
        key: "listedPrice",
        label: "Listed Price",
        render: (value: number) => (value != null ? `£${value}` : ''),
    },
];

export default function MissingInventoryDataPage() {
    return (
        <>
            <InventoryMissingDataEbayItemsTableComponent columns={inventoryMissingDataProductsColumns} />
        </>
    );
}
