import { gql } from "@apollo/client";

export const GET_PAGINATED_SOURCE_LOCATIONS_QUERY = gql`
    query GetPaginatedSourceLocations(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $name_Contains: String
        $offset: Int
    ) {
        paginatedSourceLocations(
            after: $after
            before: $before
            first: $first
            last: $last
            name_Contains: $name_Contains
            offset: $offset
        ) {
            edges {
                cursor
                node {
                    id
                    name
                    address
                    city
                    state
                    zipCode
                    country
                    createdAt
                    updatedAt
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
            }
        }
    }
`;
