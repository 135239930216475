import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconComponent from "@/components/icon/IconComponent";
import { NavigationItem, NavigationChild } from "@/types/globalTypes";
import { classNames } from "@/helpers/common/classNames";
import { SidebarNavItemChild } from "./SidebarNavItemChild";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

interface NavItemProps {
    item: NavigationItem | NavigationChild;
    isActive: boolean;
}

export const SidebarNavItem: React.FC<NavItemProps> = ({ item, isActive }) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasChildren =
        "children" in item && item.children && item.children.length > 0;

    const toggleDropdown = () => hasChildren && setIsOpen(!isOpen);

    return (
        <li>
            <Link
                to={item.href}
                onClick={(e) =>
                    hasChildren ? (e.preventDefault(), toggleDropdown()) : null
                }
                className={classNames(
                    isActive
                        ? "bg-brand dark:text-white"
                        : "hover:bg-brand dark:text-gray-200 dark:hover:text-white",
                    "group flex items-center justify-between gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                )}
            >
                <span className="flex items-center gap-x-3">
                    {"icon" in item && (
                        <IconComponent
                            iconName={item.icon}
                            className="h-6 w-6 shrink-0"
                            aria-hidden={true}
                        />
                    )}
                    {item.name}
                </span>
                {hasChildren && (
                    <span
                        className={`transition-transform duration-200 ${isOpen ? "rotate-180" : "rotate-0"}`}
                    >
                        <ChevronDownIcon className="h-5 w-5" />
                    </span>
                )}
            </Link>
            {isOpen && hasChildren && (
                <ul className="mt-1 space-y-1 pl-5">
                    {item.children.map((child, index) => (
                        <SidebarNavItemChild
                            key={index}
                            item={child}
                            isActive={child.href === location.pathname}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};
