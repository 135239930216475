import React from "react";

interface StatsComponentProps {
    statsData: Record<string, number>;
}

const StatsComponent: React.FC<StatsComponentProps> = ({ statsData }) => {
    console.log("Stats Data:", statsData);

    const stats = [
        {
            name: "Total Profit",
            stat: statsData.totalProfit.toFixed(2),
            prefix: "£",
            suffix: ""
        },
        {
            name: "Total Sales",
            stat: statsData.totalSales.toFixed(2),
            prefix: "£",
            suffix: ""
        },
        {
            name: "Return on Investment",
            stat: statsData.returnOnInvestment.toFixed(2),
            prefix: "",
            suffix: "%"
        },
        {
            name: "Profit Per Hour",
            stat: "N/A",
            prefix: "",
            suffix: ""
        },
    ];

    const stats2 = [
        {
            name: "New Listings",
            stat: statsData.newListings,
            prefix: "",
            suffix: ""
        },
        {
            name: "Products Sold",
            stat: statsData.productsSold,
            prefix: "",
            suffix: ""
        },
        {
            name: "Avg. Transaction Value",
            stat: statsData.avgTransactionValue.toFixed(2),
            prefix: "£",
            suffix: ""
        },
        {
            name: "Avg. Time to Sell",
            stat: statsData.avgTimeToSell.toFixed(2),
            prefix: "",
            suffix: " days"
        },
    ];

    return (
        <div>
            <dl className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-4">
                {stats.map((item) => (
                    <div
                        key={item.name}
                        className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:p-6"
                    >
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            {item.name}
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white">
                            {item.prefix}{item.stat}{item.suffix}
                        </dd>
                    </div>
                ))}
            </dl>
            <dl className="mt-5 grid grid-cols-2 gap-5 sm:grid-cols-4">
                {stats2.map((item) => (
                    <div
                        key={item.name}
                        className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow dark:bg-gray-800 sm:p-6"
                    >
                        <dt className="truncate text-sm font-medium text-gray-500 dark:text-white/50">
                            {item.name}
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white">
                            {item.prefix}{item.stat}{item.suffix}
                        </dd>
                    </div>
                ))}
            </dl>
        </div>
    );
}

export default StatsComponent;
